<template>
  <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
    <a-form-model  style="background: #ffffff;min-height:900px;padding-top: 20px;"
                   ref="ruleForm"
                   :model="formData"
                   :rules="rules">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="10" :md="10" :sm="24">
          <a-form-model-item ref="descChinese"
                             prop="descChinese"
                             label="指标名称"
                             :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                             :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-input v-model="formData.descChinese"
                     placeholder="检测指标中文名称"/>
          </a-form-model-item>
          <a-form-model-item
                  label="指标名称（英）"
                  :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                  :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-input
                    v-model="formData.descEnglish"
                    placeholder="请输入检测指标名称" />
          </a-form-model-item>
          <a-form-model-item
                  label="指标单位"
                  :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                  :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-input
                    v-model="formData.unit"
                    placeholder="请输入检测指标单位" />
          </a-form-model-item>
          <a-form-model-item ref="limitValue"
                             prop="limitValue"
                             label="限值"
                             :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                             :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-input v-model="formData.limitValue"
                     placeholder="限值"/>
          </a-form-model-item>
          <a-form-model-item
                  label="指标类别"
                  :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                  :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-select placeholder="请选择" v-model="formData.category">
              <a-select-option :value="1">常规指标</a-select-option>
              <a-select-option :value="2">非常规指标</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
                  label="指标类型"
                  :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                  :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-select placeholder="请选择" v-model="formData.type">
              <a-select-option
                      v-for="item in menuList"
                      :value="item.value"
                      :key="item.id"
              >{{ item.description }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
                  label="数据类型"
                  :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                  :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-select placeholder="请选择" v-model="formData.valueType">
              <a-select-option :value="0">数值型</a-select-option>
              <a-select-option :value="1">菜单型</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
                  label="菜单名称"
                  :labelCol="{lg: {span: 7}, sm: {span: 7}}"
                  :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-input
                    v-model="formData.valueMenu"
                    placeholder="请输入菜单名称" />
          </a-form-model-item>
        </a-col>
        <a-col :lg="14" :md="14" :sm="24">
          <div style="width:100%; border:1px solid #ddd; padding:0 15px; padding-bottom:15px; height:485px; overflow-y:scroll">
            <a-form-item
                    label="检测方法">
                <span
                        v-for="item in methodSelectList"
                        :key="item.id"
                        style="border:1px solid #bbb; border-radius:8px; padding:0 10px; margin:0 10px 10px 0; display:inline-block"
                        :style="item.default ? 'border-color:#ff9900; color:#ff9900' : '' "
                >{{ item.method }}
                  <font v-if="item.default" style="font-size:0.6em">[默认]</font>
                  <font style="margin-left:10px;" @click="methodSelect(false, item.id)" class="a-hover">
                    <a-icon type="close-circle" /></font>
                </span>
            </a-form-item>
            <div style="width:100%; height:1px; background-color:#ccc; margin-top:-18px;"></div>
            <div style="margin-top:17px; margin-bottom:10px;">
              <a-button style="width:15%; float:left; margin-right:5px" @click="methodManage">管理</a-button>
              <a-input
                      v-model="methodParam.code"
                      style="width:32%; float:left; margin-right:5px"
                      placeholder="检测方法代码" />
              <a-input
                      v-model="methodParam.nameStr"
                      style="width:32%; float:left"
                      placeholder="检测方法名称" />
              <a-button type="primary" style="width:15%; float:right" @click="methodSearch">查询</a-button>
            </div>
            <div style="clear:both; margin-bottom:18px;"></div>
            <a-table
                    :columns="columns"
                    :dataSource="methodShowList"
                    :pagination="pagination"
            >

              <template slot="serial" slot-scope="text, record, index">
                <template>{{ index + 1 }}</template>
              </template>
              <template slot="select" slot-scope="text, record">
                <template>
                  <a-checkbox :checked="record.select" @change="e => methodSelect(e.target.checked, record.id)"></a-checkbox>
                </template>
              </template>
              <template slot="default" slot-scope="text, record">
                <template>
                  <a-checkbox :checked="record.default" @change="e => defaultSelect(e.target.checked, record.id)"></a-checkbox>
                </template>
              </template>
              <template v-for="(col, i) in ['method', 'code', 'standards']" :slot="col" slot-scope="text, record">
                <a-input
                        :key="col"
                        v-if="record.editable"
                        style="margin: -5px 0"
                        :value="text"
                        :placeholder="columns[i].title"
                        @change="e => handleChange(e.target.value, record.key, col)"
                />
                <template v-else>{{ text }}</template>
              </template>
            </a-table>
          </div>
        </a-col>
      </a-row>
      <a-form-item
              :wrapperCol="{ span: 24 }"
              style="text-align: center"
      >
        <a-button htmlType="submit" type="primary" @click="handleSubmit">提交</a-button>
        <a-button style="margin-left: 8px" @click="goList">返回</a-button>
      </a-form-item>
    </a-form-model>
  </a-card>
</template>

<script>
import { getMenuList } from '@/api/basic/common'
import { getMethodList } from '@/api/quality/project'
import { getItemInfo, updateItemInfo, addItemInfo } from '@/api/quality/item'

export default {
  name: 'BaseForm',
  created () {
    this.getMenu()
    this.getMethod(1)
  },
  data () {
    return {
      pagination: {
        total: 0,
        pageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '15'],
        showTotal: total => `共有 ${total} 条数据`
      },
      // 表单数据
      formData: {
        descChinese: '',
        descEnglish: '',
        detectionMethodRelaxEntities: null,
        limitValue: '',
        category: 1,
        type: 1,
        unit: '',
        valueMenu: '',
        valueType: 0
      },
      roleList: [],
      rules: {
        descChinese: [{ required: true, message: "请输入中文名称", trigger: "blur" }],
        limitValue: [{ required: true, message: "请输入限值", trigger: "blur" }]
      },
      // 查询参数
      menuParam: {
        menu: '指标类型'
      },
      methodParam: {
        code: '',
        nameStr: ''
      },
      // table
      columns: [
        {
          title: '#',
          dataIndex: 'serial',
          key: 'serial',
          width: '10%',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '选择',
          dataIndex: 'select',
          key: 'select',
          width: '12%',
          scopedSlots: { customRender: 'select' }
        },
        {
          title: '默认',
          dataIndex: 'default',
          key: 'default',
          width: '12%',
          scopedSlots: { customRender: 'default' }
        },
        {
          title: '方法',
          dataIndex: 'method',
          key: 'method',
          width: '23%',
          scopedSlots: { customRender: 'method' }
        },
        {
          title: '代码',
          dataIndex: 'code',
          key: 'code',
          width: '23%',
          scopedSlots: { customRender: 'code' }
        },
        {
          title: '标准',
          dataIndex: 'standards',
          key: 'standards',
          width: '20%',
          scopedSlots: { customRender: 'standards' }
        }
      ],
      // 接口返回数据
      menuList: [],
      methodList: [],
      methodAllList: [],
      methodShowList: [],
      methodSelectList: []
    }
  },
  methods: {
    // 接口请求
    getMenu () {
      getMenuList(this.menuParam).then(response => {
        this.menuList = response.data.list
        if (this.$route.query.id === 0) {
          this.formData.type = response.data.list[0].value
        }
      })
    },
    getItem () {
      // var self = this
      if (this.$route.query.id > 0) {
        getItemInfo({ id: this.$route.query.id }).then(response => {
          // this.formData = []
          this.formData = response.data

          var methodS = response.data.detectionMethodRelaxEntities
          for (let i = 0; i < methodS.length; i++) {
            if (methodS[i].isDefault === 1) {
              this.defaultSelect(true, methodS[i].methodId)
            } else {
              this.methodSelect(true, methodS[i].methodId)
            }
          }
        })
      }
    },
    updateItem () {
      var list = []
      var obj = {}
      for (let i = 0; i < this.methodSelectList.length; i++) {
        obj = {
          code: this.methodSelectList[i].code,
          detectId: this.methodSelectList[i].detectId,
          // id: this.methodSelectList[i].id,
          isDefault: this.methodSelectList[i].default ? '1' : '0',
          isDelete: 0,
          method: this.methodSelectList[i].method,
          methodId: this.methodSelectList[i].id,
          standards: this.methodSelectList[i].standards
        }
        list.push(obj)
      }

      //获取表单数值
      this.formData.detectionMethodRelaxEntities = list
      if (this.formData.id == null || this.formData.id === 0) {
        addItemInfo(this.formData).then(response => {
          if (response.code === 0) {
            this.$message.info(`数据添加成功`)
            this.$router.back()
          } else {
            this.$message.error(`数据添加失败,错误原因:${response.msg}`)
          }
        })
      } else {
        updateItemInfo(this.formData).then(response => {
          if (response.code === 0) {
            this.$message.info(`数据更新成功`)
            this.$router.back()
          } else {
            this.$message.error(`数据更新失败,错误原因:${response.msg}`)
          }
        })
      }
    },
    getMethod (isInit) {
      getMethodList(this.methodParam).then(response => {
        var list = response.data.list
        for (let i = 0; i < response.data.list.length; i++) {
          list[i].select = false
          list[i].default = false
          list[i].show = true
        }
        if (isInit === 1) {
          this.methodAllList = list
        }
        // 遍历获取显示列表
        this.methodShowList = this.methodAllList.filter(function (item) {
          return item.show
        })
        // 获取详细信息
        this.getItem()
      })
    },
    methodManage () {
      this.$router.push({
        path: '/test/method'
      })
    },
    // 操作方法
    methodSearch () {
      getMethodList(this.methodParam).then(response => {
        this.methodList = []
        var alist = []
        for (let i = 0; i < response.data.list.length; i++) {
          alist.push(response.data.list[i].id)
        }
        this.methodList = alist
        for (let j = 0; j < this.methodAllList.length; j++) {
          if (this.methodList.includes(this.methodAllList[j].id)) {
            this.methodAllList[j].show = true
          } else {
            this.methodAllList[j].show = false
          }
        }
        // 遍历获取显示列表
        this.methodShowList = this.methodAllList.filter(function (item) {
          return item.show
        })
      })
    },
    methodSelect (v, id) {
      for (let j = 0; j < this.methodAllList.length; j++) {
        if (this.methodAllList[j].id === id) {
          this.methodAllList[j].select = v
          if (!v) {
            this.methodAllList[j].default = v
          }
        }
      }
      // 遍历获取显示列表
      this.methodShowList = this.methodAllList.filter(function (item) {
        return item.show
      })
      this.methodSelectChange()
    },
    defaultSelect (v, id) {
      for (let j = 0; j < this.methodAllList.length; j++) {
        if (this.methodAllList[j].id === id) {
          this.methodAllList[j].select = true
          this.methodAllList[j].default = true
        } else {
          this.methodAllList[j].default = false
        }
      }
      // 遍历获取显示列表
      this.methodShowList = this.methodAllList.filter(function (item) {
        return item.show
      })
      this.methodSelectChange()
    },
    // 更新选中列表
    methodSelectChange () {
      this.methodSelectList = this.methodAllList.filter(function (item) {
        return item.select
      })
    },
    goList () {
      this.$router.back()
    },
    // handler
    handleSubmit (e) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.updateItem()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
}
</script>
<style lang="less" scoped>
  .a-hover:hover {
    cursor: pointer;
  }
</style>
